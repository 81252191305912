exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-me-mdx": () => import("./../../../src/pages/about/me.mdx" /* webpackChunkName: "component---src-pages-about-me-mdx" */),
  "component---src-pages-about-suzuki-mdx": () => import("./../../../src/pages/about/suzuki.mdx" /* webpackChunkName: "component---src-pages-about-suzuki-mdx" */),
  "component---src-pages-calendar-tsx": () => import("./../../../src/pages/calendar.tsx" /* webpackChunkName: "component---src-pages-calendar-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-resources-mdx": () => import("./../../../src/pages/resources.mdx" /* webpackChunkName: "component---src-pages-resources-mdx" */),
  "component---src-pages-studio-apply-mdx": () => import("./../../../src/pages/studio/apply.mdx" /* webpackChunkName: "component---src-pages-studio-apply-mdx" */),
  "component---src-pages-studio-faq-mdx": () => import("./../../../src/pages/studio/faq.mdx" /* webpackChunkName: "component---src-pages-studio-faq-mdx" */),
  "component---src-pages-studio-policies-mdx": () => import("./../../../src/pages/studio/policies.mdx" /* webpackChunkName: "component---src-pages-studio-policies-mdx" */)
}

